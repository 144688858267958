<template>
  <div class="notepad-wrap">
    <div class="choose-wrap d-flex ai-center mb-30">
      <span class="fs-13 mr-20 text-dark-1">选择分类：</span>
      <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
      <div class="mx-15"></div>
      <el-checkbox-group v-model="checkList" @change="handleCheckedCitiesChange">
        <el-checkbox v-for="item in choose" :key="item" :label="item"></el-checkbox>
      </el-checkbox-group>
    </div>
    <hr>
    <div class="d-flex content-wrap mt-10 fs-15 text-dark-3">
      <div class="content-list mt-15">
        <el-scrollbar>
          <router-link tag="div" class="m-c-p" :to="`/notepad/${item._id}`" v-for="(item, index) in notepadList" :key="item._id">
            <span class="content-title" :class="{ 'content-title-active' : isActive === index  }" @click="changeContent(index)"> {{ item.title }} </span>
          </router-link>
          <div class="mb-30 pb-10"></div>
        </el-scrollbar>
      </div>
      <div class="content-description">
        <el-scrollbar>
          <div v-html="content || ''" class="content  px-30"></div>
        </el-scrollbar>
      </div>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return {
      checkAll: true,
      choose:['JavaScript', 'ES6', 'VUE', 'MongoDB', 'MySql', 'NodeJS'],
      checkList: ['JavaScript', 'ES6', 'VUE', 'MongoDB', 'MySql', 'NodeJS'],
      isIndeterminate: true,
      notepadList:[{
        title: '',
      }],
      isActive: 0,
      notepadListAll:[]
    }
  },
  props:['id'],
  methods:{
      async getNotepad(){
        const result = await this.$axios.get('/notepad')
        this.notepadList = result.data
        this.notepadListAll = result.data
      },
      // 改变标题列表中，选中文章标题的样式
      changeContent(index){
        this.isActive = index
      },
      handleCheckAllChange(val) {
        this.checkList = val ? this.choose : [];
        this.isIndeterminate = false;
        this.notepadList = this.notepadListAll
      },
      handleCheckedCitiesChange(value) {
        let checkedCount = value.length;
        this.checkAll = checkedCount === this.choose.length;
        this.isIndeterminate = checkedCount > 0 && checkedCount < this.choose.length;
        
        this.notepadList = this.notepadListAll.filter(item => {
          let flag = false
          item.categories.map(item => {
            if(this.checkList.indexOf(item.name) != -1){flag = true}
          })
          return flag
        })
      }
  },
  mounted(){
    this.getNotepad()
  },
  computed:{
    content(){
      if(this.notepadList.filter(item => { return item._id == this.id }).length != 0){
        const content = this.notepadList.filter(item => { return item._id == this.id })[0].content
        return content
      }
      if(this.notepadList.length >= 1){
        return this.notepadList[0].content
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/css/variables';
  .content-title-active{
    font-weight: bold;
    color: map-get($colors, 'purple');
  }
  .notepad-wrap{
    width: 140rem;
    margin: 10rem auto !important;
    hr{
      border: none;
      height: 1px;
      background: #ccc;
    }
  }
  .content-wrap{
    .content-list{
      line-height: 3.5rem;
      width: 15%;
      height: calc(100vh - 142px);
      .content-title:hover{
        color: map-get($colors, 'purple') ;
      }
    }
    .content-description{
      height: calc(100vh - 142px);
      width: 85%;
      .content{
        width: 93%;
      }
    }
    .el-scrollbar {
      height: 100%;
    }
  }
</style>